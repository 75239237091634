module.exports = {
  chenggong: 'Success',
  shibai: 'Fail',
  shi: 'Yes',
  fou: 'No',
  beizhu: 'Remarks',
  caozuo: 'Operation',
  xinzeng: 'Add',
  bianji: 'Edit',
  fasongxiaoxi: 'Send a message',
  shanchu: 'Delete',
  bizhong: 'Currency',
  queding: 'Confirm',
  quxiao: 'Cancel',
  tijiao: 'Submit',
  chongzhi: 'Reset',
  tishi: 'Prompt',
  shanchutips:
    'This operation deletes the data permanently. Do you want to continue?',
  qxz: 'Please select',
  qsr: 'Please enter',
  flmc: 'Category Name',
  yhm: 'UID',
  zhuangtai: 'Status',
  cjsj: 'Create time',
  xxsh: 'Information audit',
  tongguo: 'Adopt',
  bohui: 'Reject',
  xgmm: {
    grxx: 'Personal information',
    jmm: 'Old password',
    jmmts: 'Please enter old password',
    xmm: 'New password',
    xmmts: 'Please enter a new password',
    zcsrmm: 'New password again',
    zcmmts: 'Please enter the password again',
    lcmmbyz: 'The two passwords are inconsistent'
  },
  common: {
    home: 'Home',
    xgmm: 'Edit password',
    tcdl: 'Logout',
    cpgl: 'Product management',
    cplb: 'Product list',
    cplx: 'Product ccategories',
    fkgl: 'Risk control management',
    cxdd: 'Order management',
    jyls: 'Transaction flow',
    mmbgl: 'Buying and selling',
    xxsh: 'Audit information',
    jydw: 'Trading unit',
    jjgl: 'Fund management',
    lcjj: 'Financial fund',
    jjfb: 'Financial fund release',
    jjtz: 'Financial fund invest',
    jjjs: 'Financial fund introduce',
    xyed: 'Credit line',
    jjlx: 'Fund type',
    yhgl: 'User management',
    khlb: 'Customer list',
    khye: 'Customer balance',
    khczjl: 'Customer operation record',
    zcczjl: 'Asset operation record',
    khyzmjl: 'Verification code record',
    fkylxfs: 'Account center',
    czlb: 'Recharge list',
    txlb: 'Withdrawal list',
    jcgl: 'Course management',
    jcfl: 'Classification of tutorial',
    jclb: 'Tutorial list',
    hqgl: 'Market management',
    xinwen: 'News',
    xwtscssz: 'News push parameter setting',
    qbgl: 'Wallet management',
    qbxx: 'Wallet information',
    qblx: 'Wallet type',
    cssz: 'Parameter setting',
    jcsz: 'Basic settings',
    lbtsz: 'Slide show',
    ypsz: 'Audio settings',
    yxcssz: 'Email parameter setting',
    dxcssz: 'Sms parameter setting',
    ipdzjxgl: 'IP resolution setting',
    fkylxfssz: 'Payment and contact information setting',
    xysz: 'Agreement settings',
    xtsz: 'System setting',
    tjyhhgly: 'Add user or administrator',
    glylb: 'Administrator list',
    quhao: 'Area code',
    jsgl: 'Role management'
  },
  home: {
    jrxzyh: 'Added users today',
    zyh: 'Total users',
    yhzye: 'Total user balance',
    jrdd: "Today's order",
    khyk: 'Customer profit and loss',
    jrls: 'Flow',
    jrcz: 'Recharge',
    jrtx: 'Cash withdrawal',
    dtsxf: "Today's service charge",
    bianma: 'Order num',
    yhm: 'uid',
    gmdj: 'Purchase price',
    dqsj: 'Expire date',
    lilv: 'Rate',
    gmzj: 'Total purchase price',
    gmsye: 'Blance at the time of purchase',
    jssdj: 'Unit price at the end',
    jsscsfy: 'Charges at the end',
    jshye: 'Balance after closing',
    zhuangtai: 'Status',
    leixing: 'Type',
    cjsj: 'Operating time',
    jieduo: 'Up',
    jiekong: 'Down',
    ywc: 'Completed',
    zzjx: 'Underway'
  },
  cp: {
    cpmc: 'Product name',
    cplx: 'Product type',
    zxz: 'Min',
    zdz: 'Max',
    sjbdfw: 'Random fluctuation range',
    cpdm: 'Product code',
    suoxie: 'Abbreviation',
    ysdm: 'Color code',
    sjwfjg: 'Time play interval',
    zdtzje: 'Minimum bet amount',
    zgtzje: 'Maximum bet amount',
    ykbl: 'Rrofit and loss ratio',
    zwjj: 'Chinese Introduction',
    ywjj: 'English Introduction',
    fxsj: 'Publish time',
    fxsl: 'Issue number',
    fkbdfw: 'Risk control fluctution range',
    sfxs: 'Whether to display by default',
    sjtips:
      'Note: If the time is 60 seconds, 120 minutes or 180 minutes, separate the time with a letter comma, e.g. enter: 60,120,180.',
    zdtips:
      'Pay attention to：If minimum bet amount is: 100,200,300, separate the bet amount with letter comma, as input: 100,200,300.The last value of the setting must be completed if the time setting is not set.',
    zgtips:
      'Pay attention to：If the maximum bet amount is: 200,300,400, please separate the bet amount with letter comma, as input: 200,300,400.The last value of the setting must be completed if the time setting is not set.',
    yktips:
      'Pay attention to：If the ratio is: 50%, 75%, 80%, then separate the ratio with letter commas, such as input: 50,75,80.The last value of the setting must be completed if the time setting is not set.',
    zhouyi: 'Monday',
    zhouer: 'Tuesday',
    zhousan: 'Wednesday',
    zhousi: 'Thursday',
    zhouwu: 'Friday',
    zhouliu: 'Saturday',
    zhouri: 'Sunday',
    kssj: 'start time'
  },
  fk: {
    zdkhks: 'Loss of designated customer',
    zdkhyl: 'Profit of designated customer',
    zxfkz: 'Minimum risk control value',
    fkgl: 'Risk control probability',
    zdz: 'Max',
    zxz: 'Min',
    gailv: 'Probability',
    kstips:
      'Instructions：Please enter the user name to search and add a user. After setting, the user will lose money on all orders borrowed too much or not enough. Please be careful.If this function is stopped, please leave it blank and submit.',
    yltips:
      'Instructions：Please enter the user name to search and add a user. After setting, all orders of this user borrowed too much or borrowed too short will be profitable. Please operate carefully.If this function is stopped, please leave it blank and submit.',
    fktips:
      'Instructions：Input amount interval, within the amount interval will be based on this probability of profit and loss.Failure to do so is not subject to risk control.'
  },
  dd: {
    ztz: 'The total investment',
    dds: 'Orders',
    yingkui: 'Profit and loss',
    ddjxz: 'The order is in progress',
    ddywc: 'The orderFinish,Do not modify the'
  },
  mmb: {
    danhao: 'Order number',
    yjysl: 'Number',
    leixing: 'Type',
    cplx: 'Product type',
    jydj: 'Deal unit price',
    zdjysl: 'Max deal number',
    zxjysl: 'Min deal number',
    jyzl: 'Trading total',
    jysl: 'Deal number',
    zhuangtai: 'Review the status',
    jyzt: 'Deal status',
    quanbu: 'All',
    zzjx: 'The ongoing',
    jyqx: 'Transaction cancelled',
    dsh: 'To be reviewed',
    ysh: 'Reviewed',
    jujue: 'refuse',
    yjs: 'Has ended',
    yfk: 'Paid',
    yfb: 'Coin released',
    yqx: 'Cancelled',
    dfk: 'To be paid',
    dwfh: 'Unit',
    sfwxnyh: 'Is virtual user',
    fkylxfs: 'Payment and contact information',
    lxdh: 'Contact number',
    lxlx: 'Contact type',
    lxxx: 'Contact information',
    tongguo: 'adopt',
    gddzzjxz: 'The order is in progress',
    gddyqx: 'The order has been cancelled',
    gddyzf: 'The order has been paid',
    gddyfb: 'The order has been released'
  },
  jj: {
    biaoti: 'Title',
    sfzdyje: 'Is customized amoun',
    sjjgwf: 'Time play interval',
    jejgwf: 'Amount play interval',
    lilv: 'Profit',
    zongshu: 'Amount',
    keyong: 'Usable',
    yiyong: 'Used',
    dongjie: 'Freeze',
    sfkq: 'Is open',
    cplx: 'Product type',
    leixing: 'Fund type',
    neirong: 'Content',
    sfyxbdqtyh: 'Whether to allow binding to other users',
    bianma: 'Code',
    tianshu: 'Days',
    dqcjzs: 'Current amount',
    sjc: 'Time difference',
    mbsj: 'Full scale time',
    lcjj: 'Financial fund',
    shzt: 'Status',
    qixian: 'Term',
    cjyh: 'Create user',
    jjlx: 'Fund type',
    zjts: 'Increase days',
    fbbm: 'Release code',
    fbzs: 'Total number of releases',
    xypj: 'credit rating',
    tzpj: 'Investment rating',
    sfrz: 'identity authentication',
    jgrz: 'Agency certification',
    fanxian: 'Return money',
    tzdh: 'Investment in a single number',
    czsj: 'Operation time',
    tupian: 'Images',
    fmtp: 'Images',
    xyedzj: 'Total credit line',
    kyxyed: 'Usable credit line',
    ysyxyed: 'Used credit line',
    djxyed: 'Freeze credit line',
    eddw: 'Product type',
    xyed: 'Credit line',
    ningcheng: 'Name',
    sfqy: 'Is open',
    jjfl: 'Classification of fund',
    mingcheng: 'name',
    tsts:
      'Note: add a few more days on the basis of the original days. Expiration time = original days + added days'
  },
  yh: {
    guojia: 'Country',
    sjh: 'phone',
    youxiang: 'Email',
    zsxm: 'Realname',
    sfbs: 'Identtify label',
    fczh: 'Whether the activity',
    zcsj: 'Registration time',
    xxsh: 'Information review',
    yhfk: 'User risk control',
    zccz: 'Assets operation',
    bdqb: 'Wallet binding',
    zichan: 'Assets',
    czsl: 'Number',
    xzjj: 'Plus or minus',
    bibi: 'Coins',
    fabi: 'Legal',
    cplx: 'Product type',
    djzc: 'Frozen assets',
    djjdyy: 'Reasons for freezing/thawing',
    dongjie: 'Freeze',
    jiedong: 'Thaw',
    jbxx: 'The basic information',
    shzt: 'Information review status',
    zhdlsj: 'Last login time',
    nicheng: 'Nickname',
    xyf: 'Credit points',
    sfrzxx: 'Identify label',
    dizhi: 'Address',
    gjrzxx: 'Identity authentication information',
    zhiye: 'Professional',
    shouru: 'Revenue',
    lxdh: 'phone number',
    sfzzm: 'Front of ID card',
    sfzfm: 'Reverse side of ID card',
    sczjzp: 'Photo of hand id',
    qtzm: 'Other supporting',
    zsyh: 'Real user',
    xnyh: 'Virtual user',
    kuisun: 'Loss',
    zhengchang: 'Normal',
    yingli: 'Profit',
    tjyh: 'Add user',
    yhlx: 'User type',
    mima: 'Password',
    bbdj: 'Cions freeze',
    fbdj: 'Legal freeze',
    zongji: 'Total',
    ckxfjl: 'View consumption records',
    xfjl: 'Consumption records',
    chuangxin: 'Innovate',
    cxdj: 'Innovate freeze',
    czyy: 'Operation reason',
    czlx: 'Operation type',
    gldh: 'Doc No. Association',
    czsj: 'Operation time',
    dfdj: 'DeFi freeze',
    denglu: 'Login',
    dengchu: 'Logout',
    ipdz: 'IP address',
    yhdlpt: 'User login platform',
    gjdm: 'Country code',
    jingdu: 'longitude',
    weidu: 'latitude',
    bianhao: 'code',
    czy: 'operator',
    yonghu: 'User',
    shuliang: 'Number',
    sjyzm: 'Phone verification code',
    quhao: 'Area code',
    jssjh: 'Receive phone',
    yzm: 'Verification Code',
    sfsx: 'Is it invalid',
    sxsj: 'Failure time',
    yxyzm: 'Email verification code',
    fsyx: 'Send mailbox',
    jsyx: 'Receiving mailbox',
    yhgl: 'user management',
    lxlx: 'Contact type',
    lxxm: 'Contact name',
    lxxx: 'Contact information',
    yhk: 'Bank card',
    khyh: 'Bank of deposit',
    khxm: 'Account Name',
    yhkh: 'Bank card No',
    lxmc: 'Contact name',
    zfb: 'Alipay',
    qita: 'Other',
    xxshzt: 'Information review status',
    sqdh: 'Application No',
    txsl: 'Withdrawal quantity',
    txdz: 'Withdrawal address',
    quanbu: 'All',
    wrz: 'Not certified',
    sfrzz: 'Authentication in progress',
    sfrz: 'Identity authentication',
    gjrzz: 'Advanced certification in progress',
    gjrz: 'Advanced certification',
    sfsh: 'Identity verification',
    gjsh: 'Senior audit',
    czmm: 'Reset password',
    gzhxwzc: 'There are no assets under this account',
    jia: 'Plus',
    jian: 'Reduce',
    xiugai: 'Modify',
    shenhe: 'Audit',
    czbm: 'Recharge number',
    czlxing: 'Recharge type',
    qbdz: 'Wallet address',
    capz: 'Recharge voucher',
    czsliang: 'Recharge quantity',
    txbh: 'Withdrawal No',
    txlx: 'Withdrawal type'
  },
  jc: {
    jcfl: 'Tutorial classification',
    cjyh: 'Create user',
    biaoti: 'Title',
    neirong: 'Content'
  },
  hq: {
    biaoti: 'Title',
    jianjie: 'brief introduction',
    fmtp: 'Cover photo',
    neirong: 'Content',
    sfyjyx: 'Is it permanently valid',
    gqsj: 'Expiration time',
    sfzjxts: 'Push only',
    tsyh: 'Push user',
    sfgq: 'Expired',
    qdts: 'Start push'
  },
  qb: {
    qbdz: 'Wallet address',
    qbdzewm: 'Wallet address QR code',
    qblx: 'Wallet type',
    cplx: 'Product type',
    yonghu: 'User',
    flmc: 'Classification name'
  },
  cs: {
    wzmc: 'Website name',
    kfdz: 'Customer service address',
    lxmjdz: 'Exchange rate',
    gonggao: 'Notice',
    ggts:
      'Note: to set multiple announcements, please use | split, for example: announcement content 1 | announcement content 2 | announcement content 3',
    wzsfgb: 'Is the website closed',
    sfkfzc: 'Open registration',
    mbptsqsxf: 'Service charge for each platform',
    tzje: 'Investment amount',
    zdxdje: 'Minimum order amount',
    zgxdje: 'Maximum order amount',
    sfkftx: 'Whether to open cash withdrawal',
    mbtxsxf: 'Each cash fee',
    dbzdtxje: 'Minimum single withdrawal amount',
    dbzgtxje: 'Maximum single withdrawal amount',
    drljzgtxje: 'Cumulative maximum withdrawal amount of the current day',
    mrzgtxcs: 'Maximum withdrawal times per day',
    txsj: 'Withdrawal time',
    txgg: 'Withdrawal announcement',
    tzdz: 'Jump address',
    tupian: 'Picture',
    scyp: 'Upload audio',
    mingcheng: 'Name',
    shiting: 'Audition',
    bdyp: 'Bind audio',
    yinpin: 'Audio',
    ypbtn: 'Upload and select files',
    yptishi: 'Only MP3 and WAV files can be uploaded, and no more than 10MB',
    kjmbtsy: 'Quick coin selling prompt tone',
    jjtsy: 'Fund prompt tone',
    cztsy: 'Recharge prompt tone',
    txtsy: 'Withdrawal prompt tone',
    sjyzmtsy: 'Mobile phone verification code prompt tone',
    yxyzmtsy: 'Email verification code prompt tone',
    qdyxyz: 'Start mailbox validation',
    smtpfwq: 'SMTP server',
    smtpdk: 'SMTP port',
    fjyxdz: 'Email address',
    yxyhm: 'Mailbox user name',
    yxmm: 'Mailbox password',
    fjrmc: 'Sender name',
    qddxyz: 'Start SMS verification',
    zhanghu: 'Account',
    mima: 'Password',
    qdipdzjx: 'Start IP address resolution',
    lxmc: 'Type name',
    tjxgpz: 'Add related configuration',
    xgpz: 'Related configuration',
    peizhi: 'Configuration',
    ziduan: 'Field',
    xiangmu: 'Project',
    mrz: 'Default value',
    leixing: 'Type',
    wenben: 'Text',
    pzdh: 'Configure phone',
    scxy: 'Upload protocol',
    xymc: 'Agreement name',
    xynr: 'Agreement content',
    bdxy: 'Binding protocol',
    zcxy: 'Registration Agreement',
    yhdlxy: 'User login protocol',
    jjtzxgxy: 'Fund investment related agreements'
  },
  xt: {
    sfbs: 'Identification',
    fczh: 'Seal account number',
    juese: 'Role',
    mima: 'Password',
    eqpass: 'Confirm password',
    czmm: 'Reset password',
    zcsj: 'Registration time',
    zjycdlsj: 'Last login time',
    zsyh: 'Real user',
    xnyh: 'Virtual user',
    quanbu: 'All',
    gly: 'Administrators',
    kefu: 'customer service',
    yonghu: 'User',
    quhao: 'Area code',
    zwmc: 'Chinese name',
    ywmc: 'English name',
    mingcheng: 'Name',
    quanxian: 'Permission',
    qxlb: 'Permission list',
    yyqx: 'Already have permission',
    scqx: 'Delete permission',
    tjqx: 'Add permission'
  }
}
